<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="false"
    :return-value.sync="date"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: on2 }">
          <v-text-field
            v-model="formattedText"
            :label="label"
            :placeholder="placeholder"
            :loading="loading"
            :disabled="disabled"
            :prepend-icon="icon"
            readonly
            :color="color"
            :clearable="clearable"
            :error-messages="errorMessages"
            v-on="{ ...on, ...on2 }"
          ></v-text-field>
        </template>
        <span>{{ formattedText || placeholder }}</span>
      </v-tooltip>
    </template>
    <v-date-picker
      :locale="$store.state.language"
      :show-current="false"
      event-color="primary"
      :events="events"
      @input="handleOnInput"
      color="primary"
      :no-title="noTitle"
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="handleCancel">{{ cancelButtonLabel }}</v-btn>
      <v-btn text color="primary" @click="handleApply">{{ applyButtonLabel }}</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
  /**
   * @author ali.sireli@meyer.com.tr
   * @description DatePicker logic
   */
  export default {
    name: "DateRange",
    props: {
      value: {
        validator: (prop) => typeof prop === "object" || prop === null,
        required: true
      },
      label: {
        type: String,
        required: false,
        default() {
          return this.$t("global.date");
        }
      },
      placeholder: {
        type: String,
        required: false,
        default() {
          return this.$t("global.date");
        }
      },
      icon: {
        type: String,
        required: false,
        default: ""
      },
      color: {
        type: String,
        required: false,
        default: "tertiary"
      },
      errorMessages: {
        type: Array,
        default: () => []
      },
      cancelButtonLabel: {
        type: [String, Object],
        required: false,
        default() {
          return this.$t("buttons.cancel");
        }
      },
      applyButtonLabel: {
        type: [String, Object],
        required: false,
        default() {
          return this.$t("buttons.apply");
        }
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      clearable: {
        type: Boolean,
        required: false,
        default: true
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      noTitle: {
        type: Boolean,
        required: false,
        default: true
      },
      maxRange: {
        type: [Number, String],
        default: 9999999
      },
      rangeErrorMessage: {
        type: String,
        default() {
          return this.$t("message.max_range_error", [this.maxRange]);
        }
      }
    },
    data: () => ({
      menu: false,
      date: null,
      from: null,
      to: null
    }),
    computed: {
      formattedText: {
        get() {
          return this.value && this.value.begin && this.value.end ? `${this.value.begin} - ${this.value.end}` : null;
        },
        set(val) {
          this.$emit("input", {
            begin: null,
            end: null
          });
        }
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(val) {
          if (!val) {
            this.from = null;
            this.to = null;
            this.resetEvents();
          } else {
            this.from = val.begin;
            this.to = val.end;
            this.events(this.to);
            setTimeout(() => {
              this.setEvents();
            }, 500);
          }
        }
      }
    },
    methods: {
      resetEvents() {
        let buttons = document.querySelectorAll(".v-date-picker-table__events");
        buttons.forEach((button) => (button.parentNode.className = "v-btn v-btn--flat v-btn--floating theme--light"));
      },
      setEvents() {
        let buttons = document.querySelectorAll(".v-date-picker-table__events");
        buttons.forEach(
          (button) => (button.parentNode.className = "v-btn v-btn--active v-btn--icon v-btn--floating primary")
        );
      },
      events(date) {
        this.resetEvents();
        let isAfter = false;
        let isBefore = false;
        let isFrom = false;
        let isTo = false;
        if (this.from && this.$moment(this.from).isValid()) {
          if (this.$moment(date).isSame(this.from)) isFrom = true;
          if (this.$moment(date).isSameOrAfter(this.from)) {
            isAfter = true;
          }
        }
        if (this.to && this.$moment(this.to).isValid()) {
          if (this.$moment(date).isSame(this.to)) isTo = true;
          if (this.$moment(date).isSameOrBefore(this.to)) {
            isBefore = true;
          }
        }
        setTimeout(() => {
          this.setEvents();
        }, 100);
        if (isFrom || isTo) return "transparent";
        return isAfter && isBefore ? "transparent" : null;
      },
      handleOnInput(date) {
        if (this.$moment(date).isSame(this.from) || this.$moment(date).isSame(this.to)) {
          this.from = date;
          this.to = date;
        }
        if (!this.from) {
          this.from = date;
        } else {
          if (Math.abs(this.$moment(date).diff(this.from, "days")) > Number(this.maxRange) - 1) {
            return this.$helpers.showNotification(this.rangeErrorMessage, "error", true);
          }
          if (!this.to && this.$moment(date).isBefore(this.from)) {
            this.to = this.from;
            this.from = date;
          } else if (!this.to) {
            this.to = date;
          } else {
            if (Math.abs(this.$moment(date).diff(this.to, "days")) > Number(this.maxRange) - 1) {
              return this.$helpers.showNotification(this.rangeErrorMessage, "error", true);
            }
            let fromDiff = this.$moment(date).diff(this.$moment(this.from));
            let toDiff = this.$moment(this.to).diff(this.$moment(date));
            if (fromDiff <= toDiff) {
              this.from = date;
            } else {
              this.to = date;
            }
          }
        }
      },
      handleCancel() {
        this.menu = false;
        this.from = this.value && this.value.begin;
        this.to = this.value && this.value.end;
      },
      handleApply() {
        this.menu = false;
        if (!this.to) this.to = this.from;
        this.$emit("input", {
          begin: this.from,
          end: this.to
        });
      }
    }
  };
</script>
